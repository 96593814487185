import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Nav from './Components/Nav';
import './Components/Nav.css'
import Home from './Components/Home';
import Services from './Components/Services';
// import About from './Components/About';
import Contactmain from './Components/Contactmain';
// import Chatbot from './Components/Chatbot';
import Chat from './Components/Chatbot';
import Activities from './Components/Activities';
import Accordion from './Components/Accordion';
import Gallery from './Components/Gallery';
import ScrollToTop from './Components/Scroll';

const App = () => {
  return (
    <div>
      <BrowserRouter>
      {/* <Chat/> */}
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          {/* <Route path='/About' element={<About/>}/> */}
          <Route path='/Services' element={<Services/>}/>
          <Route path='/Activities' element={<Activities/>}/>
          <Route path='/Contact' element={<Contactmain/>}/>
          <Route path='/FAQ' element={<Accordion/>}/>
          <Route path='/Gallery' element={<Gallery/>}/>
        </Routes>
        
      </BrowserRouter>
    </div>
  );
};

export default App;