import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import logo_img from '../Images/Ruta_Logo_Brown.png'
// import logo_img_2 from '../Images/Ruta_Logo_Black (1).png'
import logo_img_3 from '../Images/Ruta_Logo_White.png'

const Nav = () => {

    // const side_bar = useRef()
    // const top_search = useRef()
    const top_nav = useRef()
    
//    const grid_btn = () =>{
//     side_bar.current.classList.add('side_open')
//    }
//    const side_close = () =>{
//     side_bar.current.classList.remove('side_open')
//    }
//    const search_btn = () =>{
//     top_search.current.classList.add('top_open')
//    }
//    const top_close = () =>{
//     top_search.current.classList.remove('top_open')
//    }
   const menu_btn = () =>{
    top_nav.current.classList.add('nav_top_open')
   }
   const top_nav_close = () =>{
    top_nav.current.classList.remove('nav_top_open')
   }
    return (
        <div>
            <header>
          <div class="header_main ">
            <div class="logo">
                {/* <h1>RUTA EQUESTRIANS LLP</h1> */}
                <Link to={'/'}><img src={logo_img} alt="" className='logo_img' /></Link>
            </div>
            <ul class="nav_bar">
              <li>
                <Link to='/'>Home</Link>
              </li>
              {/* <li>
                <Link to="/About">About</Link>
              </li> */}
              <li>
                <Link to="/Activities">Activites</Link>
              </li>
              <li>
                <Link to="/Services">Services</Link>
              </li>
              <li>
                <Link to="/Gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/Contact">Contact</Link>
              </li>
            </ul>

            <div class="cart_main  align-items-center gap-4">
                {/* <div class="bag_main d-flex">
                    <i class='bx bx-shopping-bag'></i>
                    <span>0</span>
                </div> */}
                {/* <div className="" onClick={search_btn}>
                <i class="fa-solid fa-magnifying-glass" id="search_btn"></i>
                </div> */}
                <div class="menu_main d-flex">
                    <div class="menu_btn" onClick={menu_btn}>
                    <i class='bx bx-menu' id="menu_btn"></i>
                    </div>
                    {/* <div className="" onClick={grid_btn}>
                    <i class='bx bxs-grid' id="grid_btn"></i>
                    </div> */}
                </div>
            </div>
          </div>
      </header>

      {/* <div className="side_bar" ref={side_bar}>
            <div class="">
                <div class="side_head d-flex align-items-center justify-content-between ">
                    <img src={logo_img} alt="" className='logo_img'/>
                    <div class="wrong_btn" onClick={side_close}>
                    <i class="fa-solid fa-x" id="side_close"></i>
                    </div>
                </div>
                <div class="side_social">
                    <Link to={"https://www.facebook.com/RutaEquestrians/"} class="side_social_icons">
                        <i class="fa-brands fa-facebook"></i>
                        <h6>Facebook</h6>
                    </Link>
                    <Link to={""} class="side_social_icons">
                        <i class="fa-brands fa-x-twitter"></i>
                        <h6>Twitter</h6>
                    </Link>
                    <Link to={""} class="side_social_icons">
                        <i class="fa-brands fa-dribbble"></i>
                        <h6>Dribbble</h6>
                    </Link>
                    <Link to={""} class="side_social_icons">
                        <i class="fa-brands fa-instagram"></i>
                        <h6>Instagram</h6>
                    </Link>
                    
                </div>
                <div class="side_contact">
                    <h1>+1 840 841 25 69</h1>
                    <p>info@email.com</p>
                </div>
            </div>
      </div> */}

      {/* <section className="top_search" ref={top_search}>
        <div class="">
            <div class="search_head">
                
                <img src={logo_img} alt="" className='logo_img' />
                <div class="" onClick={top_close}>
                <i class="fa-solid fa-x" id="top_close"></i>
                </div>
            </div>
            <div class="search_btn_top">
                <input type="text" placeholder="Type Words and hit enter"/>
                <i class="fa-solid fa-magnifying-glass"></i>
            </div>
        </div>
      </section> */}

      <section class="top_nav" ref={top_nav}>
        <div class="">
            <div class="top_nav_head d-flex align-items-center justify-content-between flex-wrap">
                {/* <h1 class="ruta_top">RUTA EQUESTRIANS LLP</h1> */}
                <Link to={'/'}><img src={logo_img_3} alt="" className='logo_img'/></Link>
                <div class="top_nav_wrong" onClick={top_nav_close}>
                    <span>close</span>
                <i class="fa-solid fa-x" id="top_nav_close"></i>
                </div>
            </div>

            <div class="top_links d-flex align-items-center  ">
                <div class="">
                    <ul class="top_links_sub">
                        <li><Link to={"/"}>Home <i class="fa-solid fa-arrow-right"></i></Link></li>
                        {/* <li><Link to={"/About"}>About <i class="fa-solid fa-arrow-right"></i></Link></li> */}
                        <li><Link to={"/Activities"}>Activities <i class="fa-solid fa-arrow-right"></i></Link></li>
                        <li><Link to={"/Services"}>Services <i class="fa-solid fa-arrow-right"></i></Link></li>
                        <li><Link to={"/Gallery"}>Gallery <i class="fa-solid fa-arrow-right"></i></Link></li>
                        <li><Link to={"/Contact"}>Contact <i class="fa-solid fa-arrow-right"></i></Link></li>
                    </ul>
                </div>
                <div class="top_proj">
                    <div class="top_proj_sub">
                        <h6>Have a project?</h6>
                        <h5 class="top_info text-decoration-underline">info@webisite.com</h5>
                    </div>
                    <div class="top_proj_sub ">
                        <h6>Want to Work With Us?</h6>
                        <h5 class="d-flex align-items-center gap-4">Send Brief <i class="fa-solid fa-arrow-right"></i></h5>
                    </div>
                    <div class="top_proj_sub ">
                        <h6>Want to Buy Gear?</h6>
                        <h5 class="d-flex align-items-center gap-4">Go to Shop <i class="fa-solid fa-arrow-right"></i></h5>
                    </div>
                </div>
            </div>

            <div class="top_social d-flex align-items-center gap-4 p-3">
                <Link to={"https://www.facebook.com/RutaEquestrians/"} class="side_social_icons">
                    <i class="fa-brands fa-facebook"></i>
                </Link>
                <Link to={""} class="side_social_icons">
                    <i class="fa-brands fa-x-twitter"></i>
                </Link>
                <Link to={""} class="side_social_icons">
                    <i class="fa-brands fa-dribbble"></i>
                </Link>
                <Link to={"https://www.instagram.com/rutaequestrians/"} class="side_social_icons">
                    <i class="fa-brands fa-instagram"></i>
                </Link>
            </div>
        </div>
      </section>
        </div>
    );
};

export default Nav;