import React, { useEffect } from 'react';
import './Contact.css'
import Secondnav from './Secondnav';
import './Contactmain.css'
import Contact from './Contact';
import { Link } from 'react-router-dom';

const Contactmain = () => {
    useEffect(()=>{
        document.title = 'Ruta - Contact'
    },[])
    return (
        <div>
            <div className="">
                <Secondnav/>
            </div>

            <section id="services">
        <div class="">
            <div class="our_shop_head all_head">
                <h1 class="" style={{fontWeight:'500'}}>Contacts</h1>
                <div class="text-center mt-4">
                    <i class="fa-solid fa-chevron-down text-center fs-4"></i>
                </div>
            </div>
        </div>

        <div class="cntct_time">
            <div class="cntct_time_sub">
                <i class="fa-solid fa-clock"></i>
                <div class="">
                    <h6>Open Hours</h6>
                    <p>Mon-Fri: 9 AM – 6 PM</p>
                    <p>Saturday: 9 AM – 4 PM</p>
                    <p>Sunday: Closed</p>
                </div>
            </div>
            <div class="cntct_time_sub">
                <i class="fa-solid fa-location-dot"></i>
                <div class="">
                    <h6>Address</h6>
                    <p>Moinabad, Hyderabad</p>
                    <p>- 500075
                    </p>
                    <Link to={'https://goo.gl/maps/eVVpbpsxbQ4x3xTR6'}><p class="text-decoration-underline">Check Location</p></Link>
                </div>
            </div>
            <div class="cntct_time_sub">
                <i class="fa-solid fa-headset"></i>
                <div class="">
                    <h6>Get In Touch</h6>
                    <p>Telephone: <Link to={'tel:+91 954 230 0060'} style={{color:'#999'}}>+91 954 230 0060</Link></p>
                    <p><Link to={'mailto:rutaequestrians@gmail.com'} style={{color:'#999'}}>rutaequestrians@gmail.com</Link></p>
                    <p class="text-decoration-underline">Contact Form</p>
                </div>
            </div>
        </div>

        
  </section>

  <div id="contact_form">
    <div class="cntct_sub d-flex align-items-center ">
        <div class="cntct_iframe ">
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2061.6734813460575!2d78.33719512051627!3d17.348749141477974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDIwJzU2LjIiTiA3OMKwMjAnMTMuNyJF!5e0!3m2!1sen!2sin!4v1731753138734!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Location'></iframe>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2061.6739799040415!2d78.33588828004652!3d17.348704789865444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDIwJzU2LjIiTiA3OMKwMjAnMTMuNyJF!5e0!3m2!1sen!2sin!4v1731752637124!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Location'></iframe> */}
        </div> 
        <div class="cntct_form">
            <div class="our_shop_head ">
                <h6 class="text-start">CONTACT US</h6>
                <h1 class="text-start">Have Question? <br/> Get in Touch!</h1>
            </div>
            <div class="cntct_form_main">
                <div class="cntct_input">
                    <input type="text" placeholder="Name"/>
                    <input type="text" placeholder="Last Name"/>
                </div>
                <div class="cntct_input">
                    <input type="text" placeholder="Email"/>
                    <input type="text" placeholder="Phone"/>
                </div>
                <textarea name="" id="" placeholder="Message"></textarea>
                <button class="btn get_in"><i class="fa-solid fa-paper-plane"></i> Get in Touch</button>
            </div>
        </div>
    </div>
  </div>

  

  <div className="">
    <Contact/>
  </div>

        </div>
    );
};

export default Contactmain;