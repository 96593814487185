// CoverflowCarousel.js
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Coverflow.css'

import ruta1 from '../Images/Carosuel/webp/RUTA_Horse-01_1_11zon.webp'
import ruta2 from '../Images/Carosuel/webp/RUTA_Horse-02_2_11zon.webp'
import ruta3 from '../Images/Carosuel/webp/RUTA_Horse-03_3_11zon.webp'
import ruta4 from '../Images/Carosuel/webp/RUTA_Horse-04_4_11zon.webp'
import ruta5 from '../Images/Carosuel/webp/RUTA_Horse-05_5_11zon.webp'
import ruta6 from '../Images/Carosuel/webp/RUTA_Horse-06_6_11zon.webp'
import ruta7 from '../Images/Carosuel/webp/RUTA_Horse-07_7_11zon.webp'
import ruta8 from '../Images/Carosuel/webp/RUTA_Horse-08_8_11zon.webp'
import ruta9 from '../Images/Carosuel/webp/RUTA_Horse-09_9_11zon.webp'
import ruta10 from '../Images/Carosuel/webp/RUTA_Horse-10_10_11zon.webp'
import ruta11 from '../Images/Carosuel/webp/RUTA_Horse-11_11_11zon.webp'
import ruta12 from '../Images/Carosuel/webp/RUTA_Horse-12_12_11zon.webp'
import ruta13 from '../Images/Carosuel/webp/RUTA_Horse-13_13_11zon.webp'
import ruta14 from '../Images/Carosuel/webp/RUTA_Horse-14_14_11zon.webp'

const CoverflowCarousel = () => {
  const settings = {
    infinite: true,
    // dots:true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "0",
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 4,
          slidesToScroll:1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1950,
        settings: {
          slidesToShow: 3,
          slidesToScroll:1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll:1,
          centerMode: true,
          centerPadding: "0",
          dots:true
        },
      }
      // {
      //   breakpoint: 700,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll:1,
      //     centerMode: true,
      //     centerPadding: "0",
      //     dots:true
      //   },
      // },
     
    ],
  };

  const ruta_images = [{
    id:1,
    image:ruta1
  },
  {
    id:2,
    image:ruta2
  },
  {
    id:3,
    image:ruta3
  },
  {
    id:4,
    image:ruta4
  },
  {
    id:5,
    image:ruta5
  },
  {
    id:6,
    image:ruta6
  },
  {
    id:7,
    image:ruta7
  },
  {
    id:8,
    image:ruta8
  },
  {
    id:9,
    image:ruta9
  },
  {
    id:10,
    image:ruta10
  },
  {
    id:11,
    image:ruta11
  },
  {
    id:12,
    image:ruta12
  },
  {
    id:13,
    image:ruta13
  },
  {
    id:14,
    image:ruta14
  },


]

    const [popimg,setpopimg] = useState('')
    const popopen = useRef()


    const imagehandle = (img) =>{
        setpopimg(img)
        popopen.current.style.display = 'block'
    }

    const popclose = () =>{
        popopen.current.style.display = 'none'
    }

  return (
    <div className="">
    <div className="coverflow-carousel">
      <Slider {...settings}>
        {ruta_images.map(e=>{
            return(
            <div className="carousel_cover_image">
            <img src={e.image} alt="Item 1" />
            {/* <div className="more_in">
              <small className='more_txt' onClick={()=>imagehandle(e.image)}>More Information <i class="fa-solid fa-arrow-right"></i></small>
            </div> */}
          </div>
            )
        })}

      </Slider>

    </div>

    {/* <div className="popup_cmd" ref={popopen}>
    <div className="popup_main" >
      <div className="popup">
        <div className="pop_close" onClick={popclose}>
        <i class='bx bx-x'></i>
        </div>
        <div className="pop_card">
            <div className="pop_image">
                <img src={popimg} alt="" />
            </div>
            <div className="pop_txt">
                <h1 className='pop_head'>Information</h1>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde quasi excepturi illo perspiciatis, exercitationem odio nulla corporis. Dolorem officiis saepe nesciunt eum quo, voluptates, nobis adipisci est, fuga blanditiis minus!</p>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde quasi excepturi illo perspiciatis, exercitationem odio nulla corporis. Dolorem officiis saepe nesciunt eum quo, voluptates, nobis adipisci est, fuga blanditiis minus!</p>
            </div>
        </div>

      </div>
      </div>
      </div> */}
    </div>
  );
};

export default CoverflowCarousel;
 