import React, { useEffect } from 'react';
import './Activities.css'
import Secondnav from './Secondnav';
import './About.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Contact from './Contact';

import dressage from '../Images/webp_stock/webp/Dressage_3_11zon.webp'
import show_jump from '../Images/webp_stock/webp/Show jumping_11zon.webp'
import basic_riding from '../Images/webp_stock/webp/Basic-Riding-Classes_1_11zon.webp'
import guest_lecture from '../Images/webp_stock/webp/Guest-lecture-Rides_5_11zon.webp'
import pony from '../Images/webp_stock/webp/Pony-Rides_11zon.webp'
import hack from '../Images/webp_stock/webp/Cross-Country-Rides_2_11zon.webp'
import treasure from '../Images/webp_stock/webp/Tressure Hunt_11zon.webp'
import leadership from '../Images/webp_stock/webp/Leadership-Program_7_11zon.webp'
import feeding from '../Images/webp_stock/webp/Feeding-the-horse_4_11zon.webp'
import photoshoot from '../Images/webp_stock/webp/Photoshoot-with-horses_11zon.webp'
import how_to_approach from '../Images/webp_stock/webp/How-to-approach-a-horse_6_11zon.webp'

// import CoverflowCarousel from './Coverflow';

const Activities = () => {

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 200,
    //     slidesToShow: 5,
    //     slidesToScroll: 2,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     pauseOnHover: true,
    //     responsive: [
    //         {
    //             breakpoint: 2300, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 5, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 2050, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 4, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 1350, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 3, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 850, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 2, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 1, // Show 3 cards at a time
    //                 slidesToScroll: 1, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //     ]
    //     }


    //     const testimonial_data= [{
    //         id:1,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:2,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:3,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:4,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:5,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:6,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    //     {
    //         id:7,
    //         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
    //         name:'Lisa Brown',
    //         loc:'EI PASO, TX'
    //     },
    // ]

    useEffect(()=>{
        document.title = 'Ruta - Activities '
    })

    return (
        <div>

            <div className="">
                <Secondnav/>
            </div>


            <section className='classes'>
        <div className="">
        <div class="our_shop_head all_head all_head_8">
            <h6>
            CLASSES
                </h6>
            <h1>BUILD YOUR RIDING CONFIDENCE</h1>
        </div>

        <div className="classes_card_main">
                        <div className="classes_card">
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>DRESSAGE</h6>
                                <p className='class_card_txt'>Join our dressage classes to learn how to ride with elegance and poise. This class focuses on building a partnership between you and your horse, improving your communication and performance in the arena.</p>
                            </div>
                            <img src={dressage} alt="" />
                        </div>
                        <div className="classes_card classes_card_2">
                            <img src={show_jump} alt="" />
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Show Jumping</h6>
                                <p className='class_card_txt'>Get ready for the excitement of show jumping! Perfect for all levels, this class teaches you how to jump obstacles while improving skills like timing and coordination. Prepare for thrilling rides that will boost your confidence!</p>
                            </div>
                        </div>
                        <div className="classes_card">
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Basic Riding Classes</h6>
                                <p className='class_card_txt'>New to horse riding? Check out our Basic Riding Classes! Choose from 8, 12, or 15 lessons per month to learn basic riding techniques and horse care in a friendly environment. You'll soon feel comfortable and confident in the saddle!</p>
                            </div>
                            <img src={basic_riding} alt="" />
                        </div>
                        <div className="classes_card classes_card_2">
                            <img src={guest_lecture} alt="" />
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Guest Lectures/Rides</h6>
                                <p className='class_card_txt'>Enjoy personalized lessons with our expert trainers. These private sessions allow you to focus on specific skills or simply enjoy a leisurely ride, tailored to your needs.
                                </p>
                            </div>
                        </div>
        </div>

        </div>
  </section>





  <section className='classes fun '>
        <div className="">
        <div class="our_shop_head all_head all_head_9" >
            <div className="" >
            <h6 >
                FUN ACTIVITIES
                </h6>
            <h1>FUN BEYOND RIDING!</h1>
            </div>
        </div>

        <div className="classes_card_main">
                        <div className="classes_card fun_card">
                        <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Pony Rides</h6>
                                <p className='class_card_txt'>Ideal for young riders, our pony rides are a safe and exciting introduction to horseback riding. Our caring staff ensures kids enjoy riding and learning to care for these gentle ponies.</p>
                            </div>
                            <img src={pony} alt="" />
                        </div>
                        <div className="classes_card fun_card classes_card_2">

                            <img src={hack} alt="" />

                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Hack and Cross-Country Rides</h6>
                                <p className='class_card_txt'>Experience nature on horseback with our scenic hack rides or add adventure with cross-country rides. Connect with nature while enjoying your time in the saddle.</p>
                            </div>
                        </div>
                        <div className="classes_card fun_card">
                        <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Treasure Hunt</h6>
                                <p className='class_card_txt'>Embark on an exciting treasure hunt on horseback! Solve clues and bond with friends or family while riding. It's a fantastic way to combine fun with teamwork.
                                </p>
                            </div>
                            <img src={treasure} alt="" />

                        </div>
                        <div className="classes_card fun_card classes_card_2">
                            <img src={leadership} alt="" />

                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Leadership Program</h6>
                                <p className='class_card_txt'>Our leadership program combines horseback riding with life skills, helping participants develop teamwork, confidence, and communication.

                                </p>
                            </div>
                        </div>
        </div>

        </div>
  </section>



  <section className='classes'>
        <div className="">
        <div class="our_shop_head all_head all_head_2 all_head_10">
            <h6>
            ENGAGING ACTIVITES
                </h6>
            <h1>CONNECT WITH OUR HORSE</h1>
            <p>Participate in activities that help you bond with our horses</p>
        </div>

        <div className="classes_card_main">
                        <div className="classes_card">
                            <div class="our_shop_head all_head all_head_2 ">
                                <h6 style={{fontWeight:'500'}}>Feeding the Horses</h6>
                                <p className='class_card_txt'> Learn about horse care and nutrition while feeding our friendly horses, building trust and connection.
                                </p>
                            </div>
                            <img src={feeding} alt="" />
                        </div>
                        {/* <div className="classes_card classes_card_2">
                            <img src="https://img.freepik.com/free-photo/mother-daughter-field-playing-with-horse_1157-28306.jpg?t=st=1729252259~exp=1729255859~hmac=7ff277a432c595e15d601e69180bc42ba6783e5ab368b07684f9e76f0cc159a9&w=996" alt="" />
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Treasure Hunt</h6>
                                <p className='class_card_txt'>Solve clues and enjoy a fun adventure with friends on horseback.
                                </p>
                            </div>
                        </div> */}
                         <div className="classes_card classes_card_2">
                            <img src={photoshoot} alt="" />
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Photoshoot with Horses</h6>
                                <p className='class_card_txt'>Capture special moments with our beautiful horses in a fun photoshoot session.
                                </p>
                            </div>
                        </div>
                        <div className="classes_card">
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>How to Approach a Horse</h6>
                                <p className='class_card_txt'>Gain hands-on experience in safe horse handling, which enhances your overall enjoyment of riding.
                                </p>
                            </div>
                            <img src={how_to_approach} alt="" />
                        </div>
                        {/* <div className="classes_card classes_card_2">
                            <img src="https://img.freepik.com/free-photo/woman-with-horse-forest_1303-24512.jpg?t=st=1729252370~exp=1729255970~hmac=1e71a12a01d27412b01eb2e8b8fc76b6fa3145739316a584f8eb7692ba3c35f3&w=996" alt="" />
                            <div class="our_shop_head all_head all_head_2">
                                <h6 style={{fontWeight:'500'}}>Photoshoot with Horses</h6>
                                <p className='class_card_txt'>Capture special moments with our beautiful horses in a fun photoshoot session.
                                </p>
                            </div>
                        </div> */}
        </div>

        </div>
  </section>

  {/* <section className="coverflow_caro">
  <div class="our_shop_head all_head">
            <h6>OUR LOVELY HORSES</h6>
            <h1 style={{textTransform:'uppercase'}}>A bond beyond words, with our lovely horses.</h1>
        </div>

    <CoverflowCarousel/>
  </section> */}


  {/* <section id="act_testimonials">
    <div class="">
        <div class="our_shop_head all_head">
            <h6>TESTIMONIALS</h6>
            <h1>What Our Riders Say</h1>
        </div>

        <div className="slider_main ">
            <Slider {...settings} className='slider ' >

                {testimonial_data.map(e=>{
                    return(
                        <div className="testi__sub " key={e.id} id='testimo_card'>
                    <div class="testi_icons">
                        <i class="fa-solid fa-star" style={{color:'orange'}}></i>
                        <i class="fa-solid fa-star" style={{color:'orange'}}></i>
                        <i class="fa-solid fa-star" style={{color:'orange'}}></i>
                        <i class="fa-solid fa-star" style={{color:'orange'}}></i>
                        <i class="fa-solid fa-star" style={{color:'lightgray'}}></i>
                    </div>
                    <div class="testi_txt">
                        <p>{e.txt}</p>

                        <div class="">
                            <h4>{e.name}</h4>
                            <span>{e.loc}</span>
                        </div>
                    </div>

                </div>
                    )
                })}

            </Slider>


                
        </div>
    </div>
  </section> */}

  {/* <section className='subscribe'>
    <div class="sub_main d-flex justify-content-between">
        <div class="sub_head">
            <h1>Subscribe for the </h1>
            <h1>Exclusive Updates!</h1>
        </div>
        <div class="sub_side">
        <div class="sub_inp_main d-flex gap-3">
            <div class="sub_inp">
                <input class="long_inp" type="text" placeholder="Enter Your Email Address"/>
                <div class="d-flex  align-items-center gap-3">
                <input type="checkbox" class="inp_check"/>
                <p class="fs-5">I agree to the <span class="text-decoration-underline">Privacy Policy.</span></p>
                </div>
            </div>
            <div class="sub_btn_main">
                <button class="btn horse_btn">SUBSCRIBE</button>
            </div>
        </div>
        
        </div>
    </div>
  </section> */}



  <div className="">
    <Contact/>
  </div>
            
        </div>
    );
};

export default Activities;