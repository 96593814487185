import React, { useState } from 'react';
import './Home.css'
import qua_img from '../Images/majestic-image-of-horse-horse-silhouette-with-ride-2023-11-27-05-27-47-utc_11zon.webp'
import presentation_vid from '../Images/Gallery/Videos/Ruta Equestrains_Demo_1.1 (video-converter.com) (1).mp4'
import { Link } from 'react-router-dom';

const Qualified = () => {
    // const [count1, setCount1] = useState(0);
    // const [count2, setCount2] = useState(0);
    // const sectionRef = useRef(null);
    // const num1 = 100
    // const num2 =2587
  
    // useEffect(() => {
    //   const options = {
    //     root: null, // Use the viewport as the root
    //     rootMargin: '0px',
    //     threshold: 0.1, // Trigger when 10% of the section is visible
    //   };
  
    //   const handleScroll = ([entry]) => {
    //     if (entry.isIntersecting) {
    //       startCountdown(num1, num2);
    //     }
    //   };
  
    //   const observer = new IntersectionObserver(handleScroll, options);
    //   if (sectionRef.current) {
    //     observer.observe(sectionRef.current);
    //   }
  
    //   return () => {
    //     if (sectionRef.current) {
    //       observer.unobserve(sectionRef.current);
    //     }
    //   };
    // }, []);
  
    // const startCountdown = (target1, target2) => {
    //   const duration = 5000; // Countdown duration in milliseconds
    //   const startTime = performance.now();
  
    //   const updateCount = (currentTime) => {
    //     const elapsedTime = currentTime - startTime;
    //     const progress = Math.min(elapsedTime / duration, 1);
  
    //     setCount1(Math.floor(progress * target1));
    //     setCount2(Math.floor(progress * target2));
  
    //     if (progress < 1) {
    //       requestAnimationFrame(updateCount);
    //     }
    //   };
  
    //   requestAnimationFrame(updateCount);
    // };

    const [video_play,setvideo_play] = useState(false)

    return (
        <div>
            <section id="qualified" >
        <div class="">
            <div class="qua">
                <div class="qua_1">
                    <h6>qualified trainers</h6>
                    <div class="">
                    <h1>Improve Your Riding </h1>
                    <h1>Skills with Us</h1>
                    </div>
                    <p>We appreciate your trust! Our clients choose us and our clubs because they know we are the best.</p>
                    <div class="qua_btns">
                        <div class="">
                            <button><Link to={'/Contact'} className='text-light'>Contact</Link></button>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                            <div className="" onClick={()=>setvideo_play(true)} style={{cursor:'pointer'}}>
                            <i class="fa-solid fa-play"></i>
                            </div>
                            <h6 >Video Presentation</h6>
                        </div>
                    </div>
                </div>
                {/* <div class="qua_2">
                    <div id="countdownSection">
                        <div class="d-flex count_plus_main gap-5">
                        <div class="count_plus d-flex align-items-center gap-2 ">
                            <h1 class="countdown-card" id="card1">{count1} </h1>
                            +
                        </div>
                        <div class="count_txt">
                            <h6>
                                Competitions Held</h6>
                            <p>
                                Competitions Held
                                Natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                        </div>
                        </div>
                       

                        <div class="d-flex count_plus_main gap-5">
                            <div class="count_plus count_plus_2 d-flex align-items-center gap-2 ">
                                <h1 class="countdown-card" id="card2">{count2} </h1>
                            </div>
                            <div class="count_txt">
                                <h6>
                                    Competitions Held</h6>
                                <p>
                                    Competitions Held
                                    Natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                            </div>
                    </div>
                </div> */}

                <div className="our_qua_img" style={{width:'100%',height:'100%'}}>
                  {!video_play && <img src={qua_img} alt="" style={{width:'100%',height:'100%',objectFit:'cover'}}/>}
                  {video_play && <video src={presentation_vid } controls autoPlay></video>}
                </div>
            </div>
        </div>
      </section>
        </div>
    );
};

export default Qualified;