import React, { useEffect } from 'react';
import './Services.css'
import Secondnav from './Secondnav';
import Contact from './Contact';

const Services = () => {
    useEffect(()=>{
        document.title = 'Ruta - Services'
    },[])
    return (
        <div>
           <div className="">
            <Secondnav/>
           </div>


  <section id="services">
        <div class="">
            <div class="our_shop_head all_head">
                <h1 class="" style={{fontWeight:'500'}}>Service Plus</h1>
                <div class="text-center mt-4">
                    <i class="fa-solid fa-chevron-down text-center fs-4"></i>
                </div>
            </div>
        </div>

        <div class="services_txt">
            <h1>Dear Customers,</h1>
            <p>Our primary goal is developing a secure and customizable theme framework that meets the needs of the end user. Therefore, our customers are able to create websites using our templates as easy as 1-2-3! This process requires minimum knowledge of WordPress and coding, and extended documentation and our Support Team is always at your service. However, we ask you to keep in mind that sometimes issues occur not because of templates malfunction. There might be situations when it doesn’t depend on us and the framework. For example, server settings, some extra functional that requires deep file customization, or additional plugins usage. And of course, an attempt to build a website in a very short time.</p>
            <p>We know our theme framework perfectly and have a highly-professional team of developers. They will help you to develop the website you need. If you need website customization, contact our support system for further information: <a href="/#">https://themerex.net/support/</a></p>
        </div>
  </section>

        {/* <section>
            <div className="">
            <div class="our_shop_head all_head all_head_6 ">
            <h6>
            Flexible Packages
                </h6>
            <h1>Choose the Right Package for You</h1>
            <p>We offer customised packages for both beginners and advanced riders. Our full Dressage and Show Jumping programs last about 7 months, allowing you to improve steadily and meet your goals.</p>
        </div>
            </div>
        </section> */}

        <div className="">
            <Contact/>
        </div>
        
        </div>
    );
};

export default Services;