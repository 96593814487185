import React, { useEffect, useState } from 'react';

import './Accordion.css'
import Secondnav from './Secondnav';
import Contact from './Contact';

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const handleClick = (index) => {
      setActiveIndex(index === activeIndex ? -1 : index);
    }

    const items = [
        {  id:1,
           title: "Can a total beginner become a confident rider at Ruta Equestrians?",
           content1: "Yes! Whether you've never been on a horse before or are just starting, our instructors will teach you everything you need to know. You'll start with the basics and quickly feel confident in the saddle." ,
        //    content2:"You can watch as much as you want, whenever you want, without a single ad – all for one low monthly price. There's always something new to discover, and new TV shows and movies are added every week!"
        },
        {  id:2,
           title: "What makes horse riding at Ruta Equestrians different from other schools in Hyderabad?",
           content1: "What makes Ruta Equestrians unique are our enthusiastic instructors, well-cared-for horses, and the beautiful surroundings. Whether it's recreational or competitive riding, we offer an equestrian experience that blends fun, professionalism, and personal development.",
        //    content2:""
        },
        {  id:3,
           title: "Is horseback riding safe for kids? What about accidents? ",
           content1: "Safety comes first. With professional instructors, docile horses, and top-notch safety equipment, we ensure your child is in good hands, even during the wildest pony rides!",
        //    content2:"You can also download your favourite shows with the iOS, Android, or Windows 10 app. Use downloads to watch while you're on the go and without an internet connection. Take Netflix with you anywhere."
        },
        {   id:4,   
            title: "Can I experience a thrilling ride even if I’ve never ridden a horse before?",
            content1: "Of course! At Ruta Equestrians, we know how to balance excitement with safety. Our instructors guide you through every step, ensuring you have a thrilling and unforgettable horse ride, even if it’s your first time. Expect fun, adrenaline, and a great adventure!",
            // content2:""
         },
         {  id:5,
            title: "What are the health benefits of horse riding?",
            content1: "Riding horses is not only fun, but it’s also an excellent workout for both body and mind. It improves balance, coordination, core strength, and reduces stress, boosting mental well-being.",
            // content2:""
         },
         {  id:6,
            title: "How often should I attend lessons to become a skilled rider?",
            content1: "While your lesson frequency depends on your goals, we recommend at least two lessons per week for steady progress. The more you ride, the more confident and skilled you’ll become. Consistency is key, and our instructors will create a personalized plan to suit your schedule and goals.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:7,
            title: "Can Ruta Equestrians help me overcome my fear of horses?",
            content1: "Absolutely! Many of us start with a fear of horses, and we’ve helped them transform that fear into a love for riding. Our experienced instructors guide you at your own pace, building trust and confidence with the horses.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },


         {  id:8,
            title: "Why should I choose Ruta Equestrians over any other horse riding school in Hyderabad?",
            content1: "At Ruta, we don’t just teach riding—we provide a complete horse-riding experience, including fun activities, adventure rides, and even unique events like horse yoga! Our competitive programs, expert trainers, and beloved horses make us a top choice.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },


         {  id:9,
            title: "Can I book a photoshoot with a horse, even if I’ve never ridden before?",
            content1: "Of course! Our horses are camera-ready, and we offer stunning photoshoot packages for first-time riders or anyone who loves horses.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },

         {  id:10,
            title: "How long before I can try Show Jumping or Dressage?",
            content1: "Our tailored classes ensure that, with regular practice, most riders start Show Jumping or Dressage within six months.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },

         {  id:11,
            title: "Do you have treasure hunts with horses? How does that work?",
            content1: "Yes! Our treasure hunts combine riding with adventure, where you and your horse team up to solve clues and find hidden treasures around the course.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:12,
            title: "Can I take part in a horse show/events without being a competitive rider?",
            content1: "Yes! We host fun events and showcases for all levels, where you can show off your skills without the pressure of competition.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:13,
            title: "Will horse riding really make me fit?",
            content1: "Absolutely! Horseback riding strengthens your core muscles, legs, and improves posture. You might even discover muscles you didn’t know you had ",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:14,
            title: "Can horses really help me become a better leader?",
            content1: "Yes! Our Leadership with Horses program focuses on building communication, confidence, and intuition—skills that contribute to both personal and professional growth.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:15,
            title: "How soon can I ride like a pro?",
            content1: "With our classes, you can learn the basics in just a few months and soon start mastering impressive riding skills!",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:16,
            title: "Is riding a horse more thrilling than riding a bike?",
            content1: "1000%! Riding a bike is just that, but riding a horse is partnering with a majestic, living being. It’s like real-life magic on four legs!",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:17,
            title: "Can I ride a horse like in the movies?",
            content1: "Definitely! Our advanced classes teach you how to ride like a movie star—galloping through fields or doing tricks. Cue the slow-motion music!",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:18,
            title: "Can I learn how to speak 'horse' at Ruta Equestrians?",
            content1: "Yes! We’ll teach you to read a horse’s body language and communicate with them through subtle cues—it’s like learning a secret language!",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:19,
            title: "Is horseback riding better than going to the gym?",
            content1: "Why settle for a treadmill when you can ride? It’s a full-body workout that’s way more exciting than lifting weights or running in place!",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },
         {  id:20,
            title: "What’s it like to gallop at full speed?",
            content1: "It’s pure adrenaline! Galloping on a horse feels like flying—your heart races, the wind rushes past you, and it’s the ultimate thrill ride! Consistency is key, and our instructors will create a personalized plan to suit your schedule and goals.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         },



         
     ]

     useEffect(()=>{
      document.title = 'Ruta - Accordion'
  },[])

    return (
        <div>
         <div className="">
            <Secondnav/>
         </div>

         <section>

            <div className="" style={{marginBottom:'-15px'}}>
                <div class="our_shop_head all_head ">
                    <h6>FREQUENTLY ASKED QUESTIONS</h6>
                    <h1>MOSTLY ASKED</h1>
                </div>
            </div>


            {items.map((item, index) => (


<div className="accordion" key={item.title}>
  
    <div className="accor_ques" onClick={()=>handleClick(index)}>
    <h1 ><span className='question d-flex align-items-center gap-2'><i class="fa-sharp fa-solid fa-horse-head"></i> {item.title}</span>
    <span className='plus'>
    {index !==activeIndex && <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="elj7tfr3 e164gv2o4 default-ltr-cache-l7vm9h e1svuwfo1" data-name="Plus" alt=""><path fillRule="evenodd" clipRule="evenodd" d="M17 17V3H19V17H33V19H19V33H17V19H3V17H17Z" fill="currentColor"/></svg>}
    {index ===activeIndex && <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="elj7tfr3 e164gv2o4 default-ltr-cache-l7vm9h e1svuwfo1 wrong" data-name="Plus" alt=""><path fillRule="evenodd" clipRule="evenodd" d="M17 17V3H19V17H33V19H19V33H17V19H3V17H17Z" fill="currentColor"/></svg>}
    </span>
    </h1>
    </div>
    <div className="accor_ans">
    {index === activeIndex && <div className='ans_open'><p>{item.content1}</p> {item.content2?<p>{item.content2}</p>:<div></div>}</div>}
    </div>
</div>


))}
</section>
<div className="">
    <Contact/>
</div>
        </div>
    );
};

export default Accordion;