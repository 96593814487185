import React, { useEffect } from "react";
import Secondnav from "./Secondnav";
import './Gallery.css'

// import img_1 from '../Images/Gallery/Images/1.jpg'
// import img_2 from '../Images/Gallery/Images/2.jpg'
// import img_3 from '../Images/Gallery/Images/3.jpg'
// import img_4 from '../Images/Gallery/Images/4.jpg'
// import img_5 from '../Images/Gallery/Images/5.jpg'

import vid_1 from '../Images/Gallery/Videos/1.mp4'
import vid_2 from '../Images/Gallery/Videos/2.mp4'
import vid_3 from '../Images/Gallery/Videos/3.mp4'
import vid_4 from '../Images/Gallery/Videos/4.mp4'
import vid_5 from '../Images/Gallery/Videos/5.mp4'
import vid_6 from '../Images/Gallery/Videos/6.mp4'
import vid_7 from '../Images/Gallery/Videos/7.mp4'
import vid_8 from '../Images/Gallery/Videos/8.mp4'
import vid_9 from '../Images/Gallery/Videos/9.mp4'
import vid_10 from '../Images/Gallery/Videos/10.mp4'
import vid_11 from '../Images/Gallery/Videos/11.mp4'
import vid_12 from '../Images/Gallery/Videos/12.mp4'

import presentation_vid from '../Images/Gallery/Videos/Ruta Equestrains_Demo_1.1 (video-converter.com) (1).mp4'
// import vid_13 from '../Images/Gallery/Videos/1.mp4'
// import vid_14 from '../Images/Gallery/Videos/1.mp4'






import collage from '../Images/Gallery/Images/Webp/photo-collage.png (2).webp'
import Contact from "./Contact";

const Gallery = () => {
//     const images = [
//         {
//             img:img_1
//         },
//         {
//             img:img_2
//         },
//         {
//             img:img_3
//         },
//         {
//             img:img_4
//         },
//         {
//             img:img_5
//         },

// ]

const videos = [
    {
        vid:vid_1,
   
    },
    {
        vid:vid_2,
   
    },
    {
        vid:vid_3,
   
    },

    {
        vid:vid_7,
   
    },
    {
        vid:vid_8,
   
    },
    {
        vid:vid_9,
   
    },
    {
        vid:vid_10,
   
    },
    {
        vid:vid_11,
   
    },
    {
        vid:vid_12,
   
    },
 
]

const videos_2 =[
    {
        vid:vid_4,
        
    },
    {
        vid:vid_5,
        
    },
    {
        vid:vid_6,
        
    }
]

const presentation = [{
    vid:presentation_vid
}]

useEffect(()=>{
    document.title = 'Ruta - Gallery'
},[])


  return (
    <div>
      <div className="">
        <Secondnav />
      </div>
      <section>
        <div class="our_shop_head all_head ">
          <h6>Memories</h6>
          <h1>Gallery</h1>
        </div>

        <div className="gal_img_main">
        <div class="our_shop_head all_head all_head_2">
            <h6>OUR LOVELY HORSES</h6>
            <h1 style={{textTransform:'uppercase'}}>Images</h1>
        </div>
            <img src={collage} alt="" />
        </div>

        <div className="videos_cmd">
                <div class="our_shop_head all_head all_head_2">
                <h6>OUR LOVELY HORSES</h6>
            <h1 style={{textTransform:'uppercase'}}>Presentation</h1>
        </div>
            <div className="video_main">
                {presentation.map(e=>{
                    return(
                        <div className="video">
                            <video src={e.vid} controls ></video>
                        </div>
                    )
                })}
            </div>
            </div>
        
    
            <div className="videos_cmd">
                <div class="our_shop_head all_head all_head_2">
                <h6>OUR LOVELY HORSES</h6>
            <h1 style={{textTransform:'uppercase'}}>Videos</h1>
        </div>
            <div className="video_main">
                {videos.map(e=>{
                    return(
                        <div className="video">
                            <video src={e.vid} controls ></video>
                        </div>
                    )
                })}
            </div>

            <div className="video_main video_main_2">
                {videos_2.map(e=>{
                    return(
                        <div className="video">
                            <video src={e.vid} controls ></video>
                        </div>
                    )
                })}
            </div>
            </div>


           
            


            
        
      </section>

      <div className="">
        <Contact/>
      </div>
    </div>
  );
};

export default Gallery;
