import React, { useState } from 'react';

import './Accordion.css'
import { Link } from 'react-router-dom';
// import Contact from './Contact';

const Accordion2 = () => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const handleClick = (index) => {
      setActiveIndex(index === activeIndex ? -1 : index);
    }

    const items = [
        {  id:1,
           title: "Can a total beginner become a confident rider at Ruta Equestrians?",
           content1: "Yes! Whether you've never been on a horse before or are just starting, our instructors will teach you everything you need to know. You'll start with the basics and quickly feel confident in the saddle." ,
        //    content2:"You can watch as much as you want, whenever you want, without a single ad – all for one low monthly price. There's always something new to discover, and new TV shows and movies are added every week!"
        },
        {  id:2,
           title: "What makes horse riding at Ruta Equestrians different from other schools in Hyderabad?",
           content1: "What makes Ruta Equestrians unique are our enthusiastic instructors, well-cared-for horses, and the beautiful surroundings. Whether it's recreational or competitive riding, we offer an equestrian experience that blends fun, professionalism, and personal development.",
        //    content2:""
        },
        {  id:3,
           title: "Is horseback riding safe for kids? What about accidents? ",
           content1: "Safety comes first. With professional instructors, docile horses, and top-notch safety equipment, we ensure your child is in good hands, even during the wildest pony rides!",
        //    content2:"You can also download your favourite shows with the iOS, Android, or Windows 10 app. Use downloads to watch while you're on the go and without an internet connection. Take Netflix with you anywhere."
        },
        {   id:4,   
            title: "Can I experience a thrilling ride even if I’ve never ridden a horse before?",
            content1: "Of course! At Ruta Equestrians, we know how to balance excitement with safety. Our instructors guide you through every step, ensuring you have a thrilling and unforgettable horse ride, even if it’s your first time. Expect fun, adrenaline, and a great adventure!",
            // content2:""
         },
         {  id:5,
            title: "What are the health benefits of horse riding?",
            content1: "Riding horses is not only fun, but it’s also an excellent workout for both body and mind. It improves balance, coordination, core strength, and reduces stress, boosting mental well-being.",
            // content2:""
         },
         {  id:6,
            title: "How often should I attend lessons to become a skilled rider?",
            content1: "While your lesson frequency depends on your goals, we recommend at least two lessons per week for steady progress. The more you ride, the more confident and skilled you’ll become. Consistency is key, and our instructors will create a personalized plan to suit your schedule and goals.",
            // content2:"Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see."
         } 
     ]

    return (
        <div>

            <div className="" style={{marginBottom:'-15px'}}>
                <div className="hm_accor">
                <div class="our_shop_head all_head all_head_2">
                    <h6>FREQUENTLY ASKED QUESTIONS</h6>
                    <h1>MOSTLY ASKED</h1>
                </div>
                <div className="" to={'/FAQ'}>
                    <span className='ac2_more'><Link to={'/FAQ'} className=''>More Information <i class="fa-solid fa-arrow-right"></i></Link></span>
                    
                </div>
                </div>
            </div>


            {items.map((item, index) => (


<div className="accordion" key={item.title}>
  
    <div className="accor_ques" onClick={()=>handleClick(index)}>
    <h1 ><span className='question d-flex align-items-center gap-2'><i class="fa-sharp fa-solid fa-horse-head"></i> {item.title}</span>
    <span className='plus'>
    {index !==activeIndex && <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="elj7tfr3 e164gv2o4 default-ltr-cache-l7vm9h e1svuwfo1" data-name="Plus" alt=""><path fillRule="evenodd" clipRule="evenodd" d="M17 17V3H19V17H33V19H19V33H17V19H3V17H17Z" fill="currentColor"/></svg>}
    {index ===activeIndex && <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="elj7tfr3 e164gv2o4 default-ltr-cache-l7vm9h e1svuwfo1 wrong" data-name="Plus" alt=""><path fillRule="evenodd" clipRule="evenodd" d="M17 17V3H19V17H33V19H19V33H17V19H3V17H17Z" fill="currentColor"/></svg>}
    </span>
    </h1>
    </div>
    <div className="accor_ans">
    {index === activeIndex && <div className='ans_open'><p>{item.content1}</p> {item.content2?<p>{item.content2}</p>:<div></div>}</div>}
    </div>
</div>

))}


        </div>
    );
};

export default Accordion2;